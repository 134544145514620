.item:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
.carousel-indicators li {
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

.carousel-indicators .active {
  margin: 1px 8px 1px 1px !important;
}
